<template>
    <div v-loading="loading">
        <div style="margin-top: 3.8rem;text-align: center;">
            <p style="font-weight: 20px;font-size: 20px;">智慧园区手机版</p>
            <el-input placeholder="请输入用户名" v-model="username" style="margin-top: 50px;width: 80%" clearable></el-input>
            <el-input placeholder="请输入密码" v-model="password" style="margin-top: 30px;width: 80%" show-password clearable></el-input>
            <el-button type="primary" @click="handleLogin" style="margin-top: 30px;width: 80%">登录</el-button>
        </div>

    </div>
</template>

<script>
// import { env } from '../../api/index'
// import $ from 'jquery'
export default {
    name: 'login',
    data() {
        return {
            loading: false,
            username: "",
            password: "",
        }
    },
    methods: {
        handleLogin() {
            // $.post(
            //     env + "useVisitorQRCode", {
            //         QRCodeMessage: 'VisitorQR202206140957@15'
            //     },
            //     function(data) {
            //        if (data.resultCode == 1) {
            //             
            //        }
            //     }
            // );
            let that = this;  
            that.loading = true;
            this.$post('visitorLogin', {
                username: that.username,
                password: that.password
            }).then((data) => {
                that.loading = false;
                if (data.resultCode == 1) {    
                    that.$message({
                        type: "success",
                        message: data.resultMsg
                    });
                    that.$setCookie('smUserId', data.resultMapList.id, 1)
                    that.$router.push('/')

                } else {
                    that.$message.error(data.resultMsg);
                }
            })
        },

    },
    created() {

    }
}
</script>

<style scoped>


</style>